import React, { Component } from 'react';
import AutosizeInput from 'react-input-autosize';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

class Content extends Component {
    state = {
        pw: '   '
    }
    handlePwChange = (e) => {
        // document.getElementById("blinker").style.display = "none";
        document.getElementById("pww").style.color = "white";
        this.setState({
            pw: e.target.value
        });
    }
    onEnter = () => {
        document.getElementById("pww").style.color = "red";
    }
    render() {
        return (<div className="app">
            <div className="header">
        <h1>hiya.</h1>
      </div>
      <div className="content">
        <div className="pw-header">enter password: </div>
        
        <AutosizeInput
            className="pw-input"
            name="pw-input"
            id="pww"
	        value={this.state.pw}
	        onChange={this.handlePwChange}
        /><div>_</div>
        <div className="enter-btn" onClick={this.onEnter}>>></div>

      </div>
      </div>
        );
    }
}

// <div className="blinking" id="blinker">|</div>

export default Content;